@charset "UTF-8";
a.itext {
  width: auto !important;
  height: auto !important;
}

.annot {
  display: none !important;
}

::selection {
  background-color: #d0d0ff;
}

.pcnone {
  display: none;
}

@media only screen and (max-width: 900px) {
  .pcnone {
    display: block;
  }
}

@media only screen and (max-width: 900px) {
  .spnone {
    display: none;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .bp1200none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1200none {
    display: block;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .bp1000none {
    display: none;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .bp1000none {
    display: block;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

li {
  list-style: none;
}

html {
  font-size: 62.5% !important;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-weight: 400;
}

body {
  counter-reset: number 0;
  line-height: 1.7em;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: #411c0a;
  word-wrap: break-word;
  overflow: hidden;
  font-size: 1.8rem;
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  animation: fadeInAnime 1.5s ease 0s;
  font-family: "zen-maru-gothic", sans-serif;
  background-color: #f7f6f4;
}

.inner {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  .inner {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.inner2 {
  width: calc(100% - 12%);
}

#contents_wrap {
  width: 100%;
  min-width: 100%;
}

#contents {
  width: 100%;
  max-width: 1200px;
  padding-bottom: 150px;
}

@media only screen and (min-width: 835px) and (max-width: 1250px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 834px) {
  #contents {
    padding-left: 24px;
    padding-right: 24px;
  }
}

#main {
  width: 100%;
}

#main #col_main {
  width: 100%;
}

#side {
  width: 18.75000%;
}

.map li {
  border-left: 5px solid #CCCCCC;
  list-style: none;
  margin: 0 0 15px;
  padding: 0 0 0 10px;
  text-align: left;
}

table {
  table-layout: auto;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
  table {
    table-layout: fixed;
  }
}

.tlfix table {
  table-layout: fixed;
}

table td img {
  height: auto !important;
}

.tdpd10 td {
  padding: 10px !important;
}

pre {
  margin: 0;
  padding: 0;
  white-space: pre-line;
}

b, strong {
  font-weight: bold;
}

u {
  text-decoration: line-through;
}

img {
  max-width: 100%;
  height: auto !important;
}

hr {
  border: none;
  height: 0;
  border-bottom: 1px dashed #B3B3B3;
}

span {
  font-weight: inherit;
}

u {
  text-decoration: underline;
}

input[type="button"] {
  cursor: pointer;
  padding: 5px 10px;
}

a {
  color: #17e012;
  transition: 1s;
  text-decoration: none;
}

a:hover {
  opacity: 0.6;
  transition: 1s;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .imgR, .imgL {
    max-width: 30% !important;
  }
}

@media screen and (max-width: 640px) {
  .imgR, .imgL {
    display: block;
    float: none;
    margin: 0 auto 0;
    max-width: 100% !important;
    text-align: center;
  }
}

.mincho {
  font-family: "Noto Serif JP", "Times New Roman", "游明朝体", "Yu Mincho", YuMincho, "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "ＭＳ Ｐ明朝", "MS PMincho", serif;
}

.gothic {
  font-family: "Noto Sans JP", "游ゴシック", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

@media screen and (max-width: 640px) {
  .fltL {
    float: none;
    width: 100% !important;
  }
  .fltR {
    float: none;
    width: 100% !important;
  }
  .sptal {
    text-align: left !important;
  }
  .sptar {
    text-align: right !important;
  }
  .sptac {
    text-align: center !important;
  }
  .spcenter {
    text-align: center;
    display: block;
    margin-top: 10px;
  }
}

header#global_header {
  min-width: auto;
}

footer#global_footer {
  min-width: auto;
}

header#global_header {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
  height: auto;
  text-align: center;
}

#g-nav.panelactive {
  background-color: #fff;
}

.navR {
  display: flex;
  align-items: center;
  margin-left: auto;
}

@media only screen and (min-width: 835px) and (max-width: 1463px) {
  .navR {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}

#g-nav {
  position: relative;
}

@media only screen and (max-width: 900px) {
  #g-nav {
    display: block;
  }
}

#g-nav .mainNav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 50px;
  }
}

#g-nav .mainNav li {
  position: relative;
}

#g-nav .mainNav li a {
  text-align: center;
  padding: 10px 15px;
  display: block;
  position: relative;
  color: #411c0a;
  font-weight: 500;
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  #g-nav .mainNav li a {
    font-size: 16px;
  }
}

@media only screen and (max-width: 900px) {
  #g-nav .mainNav li a {
    display: block;
    padding: 15px;
    font-size: 1.5em;
    border-bottom: 1px solid #ccc;
    text-align: left;
    position: relative;
    font-weight: 500;
    border-left: none;
  }
  #g-nav .mainNav li a:before {
    font-family: "Font Awesome 5 pro";
    content: "\f054";
    position: absolute;
    font-size: 14px;
    right: 5%;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media only screen and (max-width: 900px) {
  #g-nav {
    /*position:fixed;にし、z-indexの数値を大きくして前面へ*/
    position: fixed;
    z-index: 9999;
    /*ナビのスタート位置と形状*/
    top: -2000%;
    left: 0;
    right: 0;
    height: 100vh;
    /*ナビの高さ*/
    /*動き*/
    transition: all 0.6s;
  }
  /*アクティブクラスがついたら位置を0に*/
  #g-nav.panelactive {
    top: 0;
    margin-top: 0;
  }
}

#toggle .is-open {
  display: block;
}

.dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.dropdown li:last-of-type a {
  border-bottom: none;
}

.dropdown li a {
  background-color: #096043;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
}

@media only screen and (max-width: 900px) {
  .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #411c0a !important;
  }
}

.toggle {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

@media screen and (max-width: 834px) {
  .toggle {
    z-index: 100;
  }
}

/*ハンバーガーメニュー*/
.openBtn {
  display: none;
}

@media only screen and (max-width: 900px) {
  .openBtn {
    display: block;
  }
}

/*ボタン外側※レイアウトによってpositionや形状は適宜変更してください*/
.openBtn {
  position: fixed;
  /*ボタン内側の基点となるためrelativeを指定*/
  top: 0;
  right: 0;
  cursor: pointer;
  width: 80px;
  height: 80px;
  background-color: #096043;
  border-radius: 0 0 0 50px;
  box-shadow: -29px 28px 41px -49px #dcdada;
  z-index: 10000;
  padding-bottom: 20px;
}

.btnArea {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

/*ボタン内側*/
.openBtn span {
  display: inline-block;
  transition: all .4s;
  /*アニメーションの設定*/
  height: 2px;
  border-radius: 5px;
  background: #fff;
  width: 35%;
}

.openBtn span:nth-of-type(2) {
  margin: 5px 0;
}

/*activeクラスが付与されると線が回転して×になり、Menu⇒Closeに変更*/
.openBtn.active span:nth-of-type(1) {
  top: 14px;
  left: 18px;
  transform: translateY(5px) rotate(-45deg);
  width: 40%;
}

.openBtn.active span:nth-of-type(2) {
  opacity: 0;
}

.openBtn.active span:nth-of-type(3) {
  top: 26px;
  left: 18px;
  transform: translateY(-8px) rotate(45deg);
  width: 40%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px 80px;
  background-color: rgba(255, 255, 255, 0.8);
}

@media only screen and (min-width: 835px) and (max-width: 1463px) {
  .header {
    width: 100%;
    padding: 20px 20px;
  }
}

@media screen and (max-width: 834px) {
  .header {
    width: 100%;
    padding: 24px;
    display: block;
    text-align: left;
  }
}

.header .h-logo {
  position: relative;
}

@media screen and (max-width: 834px) {
  .header .h-logo img {
    width: 60% !important;
  }
}

.header .h-logo .dropdown {
  width: 250px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: none;
}

@media only screen and (max-width: 900px) {
  .header .h-logo .dropdown {
    position: inherit;
    transform: none;
    top: 0;
    left: 0;
    margin: auto;
    width: 100%;
  }
}

.header .h-logo .dropdown li:last-of-type a {
  border-bottom: none;
}

.header .h-logo .dropdown li a {
  background-color: #096043;
  display: block;
  color: #fff !important;
  border-bottom: 1px solid #fff;
  padding: 15px !important;
  font-weight: bold;
}

@media only screen and (max-width: 900px) {
  .header .h-logo .dropdown li a {
    border-bottom: 1px solid #ccc;
    background-color: #fff;
    color: #411c0a !important;
  }
}

.header {
  z-index: 9999;
  position: fixed;
  top: 0;
  height: fit-content;
  left: 0;
  right: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
  will-change: transform;
  -webkit-transition: -webkit-transform .4s ease;
  transition: -webkit-transform .4s ease;
  transition: transform .4s ease;
  transition: transform .4s ease, -webkit-transform .4s ease;
}

.header--unpinned {
  -webkit-transform: translateY(-150%);
  transform: translateY(-150%);
}

.header--pinned {
  background-color: #fff;
}

@media only screen and (max-width: 900px) {
  .header--pinned {
    background-color: inherit;
  }
}

.header--pinned #g-nav {
  display: block;
}

.fix {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
}

@media only screen and (max-width: 900px) {
  .fix {
    bottom: 0;
    top: unset;
    left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
}

.fix a {
  writing-mode: vertical-lr;
  color: #fff;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  padding: 20px;
  font-size: 1.2em;
}

@media only screen and (max-width: 900px) {
  .fix a {
    writing-mode: inherit;
    width: 50%;
    border-radius: 50px;
    justify-content: center;
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

.fix a img {
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .fix a img {
    margin-bottom: 0;
  }
}

.fix .h_mail {
  background-color: #96534c;
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .fix .h_mail {
    margin-bottom: 0;
    margin-right: 10px;
  }
}

.fix .h_cart {
  background-color: #096043;
}

.h_contact {
  margin-left: 50px;
}

@media only screen and (max-width: 900px) {
  .h_contact {
    margin-left: 0;
    margin-right: 9rem;
  }
}

@media screen and (max-width: 834px) {
  .h_contact {
    display: none;
  }
}

.h_contact .h_tel {
  color: #411c0a;
  font-size: 1.5em;
  font-weight: 500;
}

.sp_contact {
  display: none;
}

.sp_contact .h_tel {
  color: #411c0a;
  font-size: 1.5em;
  font-weight: 500;
}

@media screen and (max-width: 834px) {
  .sp_contact {
    display: block;
    position: fixed;
    bottom: 10px;
    left: 10px;
    z-index: 9999;
  }
}

.mainWrap {
  position: relative;
}

.mainArea {
  width: calc(100% - 160px);
  margin: auto;
}

@media screen and (max-width: 834px) {
  .mainArea {
    width: calc(100% - 44px);
  }
}

.mainArea img {
  border-radius: 10px;
  width: 100% !important;
}

.subArea {
  padding: 10rem 1rem;
  background-color: #F5F0EC;
}

@media screen and (max-width: 834px) {
  .subArea {
    padding: 5rem 24px;
  }
}

.subArea h1 {
  font-size: clamp(32px, 3vw, 38px);
  line-height: 1.25em;
  font-weight: bold;
}

.subArea div {
  color: #ccc;
}

.pankuzu {
  margin-top: 12px;
  margin-bottom: 4.8rem;
  font-size: 1.4rem;
  display: flex;
  color: #411c0a;
}

@media screen and (max-width: 640px) {
  .pankuzu {
    margin-top: 0;
  }
}

.pankuzu ul div::before {
  content: "\f105";
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  padding: 0 10px;
}

.pankuzu ul li:last-child a {
  pointer-events: none;
  color: #21120e;
  text-decoration: none;
}

footer#global_footer {
  background-color: #96534c;
  padding: 80px 0 150px;
  text-align: left;
  color: #fff;
}

@media only screen and (max-width: 900px) {
  footer#global_footer {
    text-align: center;
  }
}

footer#global_footer .footer {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 160px);
  margin: auto;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .footer {
    display: block;
    width: calc(100% - 44px);
  }
}

footer#global_footer .footer .ft_L {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .footer .ft_L {
    margin-bottom: 30px;
  }
}

footer#global_footer .footer .ft_L .ft_logo {
  margin-bottom: 50px;
}

footer#global_footer .footer .ft_R {
  display: flex;
  flex-direction: column;
}

footer#global_footer .ftNav {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  font-size: 14px;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .ftNav {
    justify-content: flex-start;
  }
}

footer#global_footer .ftNav .ftnav_02, footer#global_footer .ftNav .ftnav_03, footer#global_footer .ftNav .ftnav_04, footer#global_footer .ftNav .ftnav_05 {
  margin-left: 50px;
  margin-bottom: 30px;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav .ftnav_02, footer#global_footer .ftNav .ftnav_03, footer#global_footer .ftNav .ftnav_04, footer#global_footer .ftNav .ftnav_05 {
    margin-left: 0;
    margin-top: 30px;
  }
}

footer#global_footer .ftNav li {
  text-align: left;
}

@media screen and (max-width: 834px) {
  footer#global_footer .ftNav li {
    width: 100%;
  }
}

footer#global_footer .ftNav li a {
  font-size: 14px;
  text-align: left;
  padding: 10px 10px 10px 2rem;
  display: block;
  color: #fff;
  line-height: 1em;
  position: relative;
}

footer#global_footer .ftNav li a:before {
  content: url(../images/home/nav_arrow.png);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

footer#global_footer .ftnav_06 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
}

footer#global_footer .ftnav_06 li {
  font-size: 14px;
}

footer#global_footer .ftnav_06 li:first-of-type a::after {
  content: "/";
  margin: 0 20px;
}

footer#global_footer .ftnav_06 li a {
  color: #fff;
}

footer#global_footer .copy {
  margin-top: auto;
}

@media only screen and (max-width: 900px) {
  footer#global_footer .copy {
    margin-top: 30px;
  }
}

footer#global_footer #copy {
  color: #fff !important;
  font-size: 14px;
}

footer#global_footer #copy a {
  text-decoration: none;
  color: #fff !important;
}

.pagetop {
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 9999;
}

.title_01 h2 {
  font-size: clamp(30px, 3vw, 36px);
  line-height: 2em;
  color: #96534c;
  font-weight: bold;
}

.title_01 h2 .deco {
  color: #cccccc;
  font-size: 16px;
  display: block;
  font-weight: 400;
  line-height: 1em;
}

.title_02 h2 {
  font-size: clamp(30px, 3vw, 36px);
  line-height: 5em;
  color: #96534c;
  font-weight: bold;
  writing-mode: vertical-lr;
}

.title_02 h2 .deco {
  color: #cccccc;
  font-size: 16px;
  font-weight: 400;
  line-height: 1em;
  writing-mode: horizontal-tb;
  margin-top: 15px;
}

.news {
  max-height: 200px;
  overflow-y: auto;
}

.news dt,
.news dd {
  border: none !important;
}

@media screen and (max-width: 640px) {
  .news dt,
  .news dd {
    width: 100% !important;
  }
}

.news dt {
  color: #96534c;
  font-weight: 500;
}

.news dl {
  border-bottom: 1px solid #f7f7f7;
  padding: 15px 0;
}

@media screen and (max-width: 640px) {
  .news dl {
    display: block !important;
  }
}

.text div {
  line-height: 2.6em;
}

.cont_01 {
  background-color: #fff;
  padding: 100px 0;
}

.cont_01 .inner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner {
    display: block;
  }
}

.cont_01 .inner .flxL {
  width: 20%;
}

.cont_01 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_01 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_01 .inner .flxR {
  width: 80%;
}

@media screen and (max-width: 834px) {
  .cont_01 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_02 {
  background-color: #fff;
  padding: 100px 0;
}

.cont_02 .in {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 160px);
  margin: auto;
}

@media screen and (max-width: 834px) {
  .cont_02 .in {
    display: block;
  }
}

.cont_02 .in .flxL {
  width: 20%;
}

.cont_02 .in .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_02 .in .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 .in .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_02 .in .flxR {
  width: 80%;
}

@media screen and (max-width: 834px) {
  .cont_02 .in .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

@media only screen and (min-width: 835px) and (max-width: 1000px) {
  .cont_02 .in {
    display: block;
  }
  .cont_02 .in .flxL, .cont_02 .in .flxR {
    width: 100% !important;
  }
  .cont_02 .in .title_02 > div {
    text-align: center !important;
  }
  .cont_02 .in .title_02 > div h2 {
    margin: auto;
    writing-mode: inherit;
    line-height: 2em;
  }
  .cont_02 .in .title_02 > div h2 .deco {
    display: block;
    margin-top: 0;
  }
}

@media screen and (max-width: 834px) {
  .cont_02 .in {
    width: calc(100% - 44px);
  }
  .cont_02 .in .title_02 > div {
    text-align: center !important;
  }
  .cont_02 .in .title_02 > div h2 {
    margin: auto;
    writing-mode: inherit;
    line-height: 2em;
  }
  .cont_02 .in .title_02 > div h2 .deco {
    display: block;
    margin-top: 0;
  }
}

.cont_03 {
  background-color: #f5f0ec;
  padding: 150px 0;
}

.cont_03 .inner {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_03 .inner {
    display: block;
  }
}

.cont_03 .inner .flxL {
  width: 48%;
}

.cont_03 .inner .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_03 .inner .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_03 .inner .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_03 .inner .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_03 .inner .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_03 .inner .flxR {
  transition-delay: .2s;
}

@media screen and (max-width: 640px) {
  .cont_03 .inner .flxR .imgR, .cont_03 .inner .flxR .imgL {
    float: right;
  }
}

.cont_03 .inner .img {
  border-radius: 10px;
}

.cont_04 {
  background-color: #f5f0ec;
  padding: 150px 0;
}

.cont_05 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_05 {
    display: block;
  }
}

.cont_05 .flxL {
  width: 48%;
}

.cont_05 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_05 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_05 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_05 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .cont_05 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_05 .flxL .h_tel {
  font-size: 1.5em;
  color: #411c0a;
  font-weight: 500;
}

.cont_05 .flxL img {
  width: auto !important;
}

@media screen and (max-width: 640px) {
  .cont_05 .flxL .text div {
    text-align: center !important;
  }
}

.cont_06 {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 834px) {
  .cont_06 {
    display: block;
  }
}

.cont_06 .flxL {
  width: 30%;
}

.cont_06 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .cont_06 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .cont_06 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.cont_06 .flxR {
  width: 68%;
}

@media screen and (max-width: 834px) {
  .cont_06 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.cont_06 .flxL .h_tel {
  font-size: 1.5em;
  color: #411c0a;
  font-weight: 500;
}

.cont_06 .flxL img {
  width: auto !important;
}

@media screen and (max-width: 640px) {
  .cont_06 .flxL .text div {
    text-align: center !important;
  }
}

.td_01 td {
  text-align: center !important;
  color: #096043;
  border: none !important;
  padding: 15px 10px !important;
}

.td_01 tr {
  border-bottom: 1px solid #ebd5cd;
}

.td_01 .td_L, .td_01 .td_top {
  color: #411c0a;
}

.td_01 .td_L div, .td_01 .td_top div {
  font-weight: 500;
  color: #96534c;
}

.td_02 td {
  text-align: center !important;
  border: none !important;
  padding: 25px 15px !important;
  font-size: 16px;
  background-color: #fff;
}

.td_02 tr {
  border-bottom: 1px solid #ebd5cd;
}

.td_02 .td_L, .td_02 .td_top {
  color: #411c0a;
}

.td_02 .td_top {
  background-color: #F1F0EE;
}

.td_02 .td_top div {
  color: #96534c;
  font-weight: 500;
}

.td_02 .td_L {
  background-color: #F1F0EE;
}

.td_02 .td_L div {
  font-weight: 500;
  color: #96534c;
}

.bnr a {
  background-color: #f1f0ee;
  position: relative;
  display: block;
  padding: 64px 64px 64px 10rem;
  border-radius: 10px;
}

.bnr a .in {
  font-size: clamp(30px, 3vw, 36px);
  line-height: 2em;
  color: #411c0a;
  font-weight: 500;
  position: relative;
  padding-left: 15rem;
  transition: 1s;
}

@media screen and (max-width: 640px) {
  .bnr a .in {
    padding-left: 13rem;
  }
}

.bnr a .in:before {
  content: url(../images/home/item_icon.png);
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: 1s;
}

@media screen and (max-width: 640px) {
  .bnr a {
    padding: 64px 30px;
  }
}

.bnr a:before {
  content: url(../images/home/brown_icon.png);
  position: absolute;
  right: 111px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: 1s;
}

@media screen and (max-width: 640px) {
  .bnr a:before {
    right: 50px;
  }
}

.bnr a .deco {
  color: #cccccc;
  font-size: 16px;
  display: block;
  font-weight: 400;
  line-height: 1em;
}

.bnr a:hover {
  opacity: 1 !important;
  background-color: #96534c;
  transition: 1s;
}

.bnr a:hover::before {
  filter: brightness(0) invert(1);
  transition: 1s;
}

.bnr a:hover .in {
  color: #fff;
  transition: 1s;
}

.bnr a:hover .in::before {
  filter: brightness(0) invert(1);
  transition: 1s;
}

.flex_01 {
  justify-content: center;
  align-items: stretch;
}

@media screen and (max-width: 640px) {
  .flex_01 {
    justify-content: space-between;
  }
}

.flex_01 .box {
  width: calc((100% - 150px) / 4);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-right: 50px;
  background-color: #fff;
  padding: 36px 30px;
  border-radius: 10px;
  margin-bottom: 50px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_01 .box {
    width: calc((100% - 90px) / 4);
    margin-right: 30px;
    padding: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex_01 .box {
    width: calc((100% - 30px) / 2);
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.flex_01 .box:nth-of-type(4n) {
  margin-right: 0;
}

.flex_01 .box article > div {
  margin-top: 30px;
  font-weight: 500;
}

.flex_01 .box:hover img {
  transition: .5s;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}

.flex_02 {
  justify-content: space-between;
}

.flex_02 .box {
  width: calc((100% - 100px) / 3);
  text-align: right;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_02 .box {
    width: calc((100% - 30px) / 3);
  }
}

@media screen and (max-width: 640px) {
  .flex_02 .box {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex_02 .box img {
  border-radius: 10px;
  transition: 2s;
}

.flex_02 .box img:hover {
  transform: scale(1.2);
}

.flex_02 .box article > div {
  position: relative;
  z-index: 1;
  padding-top: 10px;
  background-color: #fff;
}

.flex_02 .box article > div a {
  color: #411c0a;
  font-weight: 500;
  font-size: clamp(16px, 2.4vw, 24px);
  position: relative;
  padding: 10px 5rem 10px 0;
  line-height: 1.5em;
  display: block;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex_02 .box article > div a {
    font-size: 16px;
  }
}

@media screen and (max-width: 640px) {
  .flex_02 .box article > div a {
    font-size: 24px;
  }
}

.flex_02 .box article > div a:before {
  content: url(../images/home/green_arrow.png);
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 43px;
  height: 43px;
}

.flex_03 {
  justify-content: space-between;
}

.flex_03 .box {
  width: calc((100% - 100px) / 3);
}

@media screen and (max-width: 640px) {
  .flex_03 .box {
    width: 100%;
    margin-bottom: 30px;
  }
}

.flex_03 .box > div a {
  background-color: #f1f0ee;
  display: block;
  padding: 50px 15px;
  border-radius: 10px;
  text-align: center;
  color: #411c0a;
  font-weight: 500;
  font-size: clamp(16px, 2.4vw, 24px);
  position: relative;
  line-height: 1em;
  display: block;
}

@media screen and (max-width: 640px) {
  .flex_03 .box > div a {
    font-size: 24px;
  }
}

.flex_03 .box > div a .deco {
  color: #cccccc;
  font-size: 14px;
  display: block;
  font-weight: 400;
  line-height: 1em;
  margin-top: 15px;
}

.flex_03 .box > div a:hover {
  background-color: #96534c;
  color: #fff;
  transition: 1s;
}

.gmap iframe {
  width: 100%;
  border-radius: 10px;
  height: 300px;
}

.animal_anim {
  animation: poyooon 0.9s linear 0s 1;
}

@keyframes poyooon {
  0% {
    transform: scale(1, 1) translate(0%, 0%);
  }
  10% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  40% {
    transform: scale(1.2, 0.8) translate(0%, 15%);
  }
  50% {
    transform: scale(1, 1) translate(0%, 0%);
  }
  60% {
    transform: scale(0.9, 1.2) translate(0%, -100%);
  }
  75% {
    transform: scale(0.9, 1.2) translate(0%, -20%);
  }
  85% {
    transform: scale(1.2, 0.8) translate(0%, 15%);
  }
  100% {
    transform: scale(1, 1) translate(0%, 0%);
  }
}

.top-sns .sns_text {
  display: none;
}

.top-sns .slick-slide {
  margin-right: 15px;
}

.btn a {
  color: #fff;
  position: relative;
  display: block;
  padding: 20px;
  width: 300px;
  font-size: 1.12em;
  background-color: #096043;
  border-radius: 10px;
  margin: auto;
}

.btn a:before {
  font-family: "Font Awesome 5 pro";
  content: "\f061";
  position: absolute;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: none;
  border: none;
  border-radius: 10px;
  box-shadow: inset 0 0 2px #ccc;
}

::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 10px;
  box-shadow: none;
}

div.items > article a {
  color: #111;
  text-decoration: none !important;
  font-size: 16px;
}

div.under ul li a {
  font-weight: 400;
  opacity: 1;
  border: 1px solid #333;
  color: #333 !important;
  padding: 10px;
}

div.under ul li a:hover {
  border: 1px solid #b0ddd9;
  background: #b0ddd9;
  color: #fff !important;
}

div.select > select {
  height: 100%;
}

div.search form input {
  padding: 10px 37px 10px 5px;
}

div.order ul li a {
  color: #111;
}

div.items > article p.price {
  color: red;
}

div.crumb {
  display: none;
}

div.item_view div.item_option {
  width: 100% !important;
}

div.item_option dl,
button.addcart {
  width: 50% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  div.item_option dl,
  button.addcart {
    margin: 0 auto;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .addcart {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

div.item_view div.struct {
  display: block;
}

div.photo div.large {
  width: auto;
}

div.photo {
  margin-left: 0;
}

h2.title_h2 {
  background: none !important;
  border-top: none !important;
  border-bottom: 1px solid #CCCCCC;
  font-size: 20px !important;
  line-height: 1.3;
  margin: 65px 0 20px !important;
  padding: 10px !important;
}

h2.title_h2 span {
  border: none !important;
  padding: 0 !important;
}

ul.item_list li {
  margin-bottom: 10px !important;
}

ul.item_list li h3.title_h3 {
  font-size: 18px !important;
}

.btn_cart a {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #000000 !important;
  background-size: 30px auto !important;
  color: #FFFFFF !important;
  display: block !important;
  padding: 10px 10px 10px 50px !important;
  width: 100% !important;
  border: 1px solid #ffffff;
}

.btn_cart a:hover {
  background: url(../images/common/ico_cart_w.png) no-repeat 10px 12px #CCCCCC !important;
  background-size: 30px auto !important;
}

.sort_catalog {
  overflow: hidden;
}

.sort_catalog ul {
  display: flex;
}

.item_title,
.wishlist {
  margin: 0 !important;
}

.shop_cont h2.title_h2,
.item_info h2.title_h2 {
  border-top: 2px solid #CCCCCC;
}

.shop_cont a,
.item_info a {
  color: #CCCCCC;
}

.shop_cont a:hover,
.item_info a:hover {
  text-decoration: none;
}

.item_title h2 {
  border-top: 2px solid #CCCCCC;
  border-bottom: 1px solid #CCCCCC;
}

.shop_cont table {
  table-layout: auto;
}

.shop .items {
  margin-left: 0;
}

@media screen and (max-width: 640px) {
  .shop .items {
    display: block !important;
  }
}

.shop .items article {
  width: 22.333% !important;
  margin-left: 1% !important;
  margin-right: 1% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .shop .items article {
    width: 48% !important;
  }
}

@media screen and (max-width: 640px) {
  .shop .items article {
    width: 100% !important;
  }
}

.shop .items article .photo {
  width: 100% !important;
}

.shop .items article .name {
  color: #96534c !important;
  font-weight: bold;
}

.shop .items article .price {
  color: #411c0a !important;
}

.shopPage div.items > article .thumb {
  z-index: 2;
  position: relative;
}

.shopPage div.items > article .hoverImg {
  position: absolute;
  top: 0;
  left: 0;
}

.shopPage div.items > article a.photo {
  position: relative;
}

.shopPage div.items > article a {
  text-decoration: none;
}

.shopPage div.items > article a.photo {
  width: 100%;
  height: auto;
  background-image: none !important;
}

.shopPage div.items > article a.photo img {
  display: block;
  width: 100% !important;
  height: 264px !important;
  -o-object-fit: cover;
  object-fit: cover;
  font-family: "object-fit: cover;";
}

#main #col_side1 {
  display: none;
}

.shopPage #main #col_side1 {
  display: block;
  padding-right: 24px;
}

@media screen and (max-width: 834px) {
  .shopPage #main #col_side1 {
    width: 100% !important;
  }
}

.shopPage #col_main {
  width: calc(100% - 300px) !important;
}

@media screen and (max-width: 834px) {
  .shopPage #col_main {
    width: 100% !important;
  }
}

.view_cart a {
  background-color: #096043;
  display: block;
  color: #fff;
  text-align: center;
  padding: 10px;
}

.view_cart a i {
  margin-right: 10px;
}

.flex1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex1._top {
  align-items: flex-start;
}

.flex1._center {
  align-items: center;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:before {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 1;
}

.flex1:after {
  width: calc(100% / 1 - 1%);
  content: "";
  order: 2;
}

.flex1 .box {
  width: calc(100% / 1 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex1 .box img {
  width: 100% !important;
}

.flex2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex2._top {
  align-items: flex-start;
}

.flex2._center {
  align-items: center;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:before {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 1;
}

.flex2:after {
  width: calc(100% / 2 - 1%);
  content: "";
  order: 2;
}

.flex2 .box {
  width: calc(100% / 2 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex2 .box img {
  width: 100% !important;
}

.flex3 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex3._top {
  align-items: flex-start;
}

.flex3._center {
  align-items: center;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:before {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 1;
}

.flex3:after {
  width: calc(100% / 3 - 1%);
  content: "";
  order: 2;
}

.flex3 .box {
  width: calc(100% / 3 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex3 .box img {
  width: 100% !important;
}

.flex4 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex4._top {
  align-items: flex-start;
}

.flex4._center {
  align-items: center;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:before {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 1;
}

.flex4:after {
  width: calc(100% / 4 - 1%);
  content: "";
  order: 2;
}

.flex4 .box {
  width: calc(100% / 4 - 1%);
  margin-bottom: 2%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  ._tabwidth1 .box {
    width: 100%;
  }
  ._tabwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._tabwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

@media screen and (max-width: 640px) {
  ._spwidth1 .box {
    width: 100%;
  }
  ._spwidth2 .box {
    width: calc(100% / 2 - 1%);
  }
  ._spwidth3 .box {
    width: calc(100% / 3 - 1%);
  }
}

.flex4 .box img {
  width: 100% !important;
}

.square._100 .square-inner:before {
  padding-top: 100%;
}

.square._75 .square-inner:before {
  padding-top: 75%;
}

.square._66 .square-inner:before {
  padding-top: 66%;
}

.square td .square-inner {
  display: block;
  position: relative;
}

.square td .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square td .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .square-inner {
  display: block;
  position: relative;
}

.square > .square-inner .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.square > .box .itext {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .itext img {
  float: none !important;
  margin-right: 0 !important;
}

.square > .box .square-inner {
  display: block;
  position: relative;
}

.square > .box .square-inner:before {
  position: relative;
  z-index: 1;
  content: "";
  display: block;
  width: 100% !important;
}

.square > .box .square-inner img {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  object-fit: cover;
  font-family: "object-fit:cover;";
  width: 100% !important;
  height: 100% !important;
}

.bg100 {
  margin-right: calc(((100vw - 100%) / 2) * -1) !important;
  margin-left: calc(((100vw - 100%) / 2) * -1) !important;
}

.flex2cl_01 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 {
    flex-direction: column-reverse;
  }
}

.flex2cl_01 .flxL {
  width: 57%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxL {
    width: 100%;
  }
}

.flex2cl_01 .flxR {
  width: 40%;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_01 .flxR {
    width: 100%;
    margin-bottom: 20px;
  }
}

.flex2cl_01 .flxR .img {
  width: 100%;
}

.flex2cl_01 .flxR .img img {
  width: 100% !important;
}

.flex2cl_02 {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -4%;
  margin-bottom: 50px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 {
    margin-left: -2.4390%;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex2cl_02 .box {
  position: relative;
  width: 46%;
  margin-left: 4%;
  margin-bottom: 20px;
}

@media screen and (max-width: 1200px) {
  .flex2cl_02 .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
  }
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .flex2cl_02 .box {
    width: 100%;
    margin-left: 0;
  }
}

.flex2cl_02 .box img {
  width: 100% !important;
}

.flex2cl_02 .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex2cl_02 .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex3cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -2.43902%;
}

@media screen and (max-width: 1200px) {
  .flex3cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex3cl .box {
  position: relative;
  width: 30.89431%;
  margin-left: 2.43902%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex3cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex3cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex3cl .box img {
  width: 100% !important;
}

.flex3cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex3cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.flex4cl {
  border: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  margin-left: -3.22581%;
}

@media screen and (max-width: 1200px) {
  .flex4cl {
    margin-left: -2.4390%;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl {
    justify-content: space-between;
    margin-left: 0;
  }
}

.flex4cl .box {
  position: relative;
  width: 21.77419%;
  margin-left: 3.22581%;
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .flex4cl .box {
    width: 47.5609%;
    margin-left: 2.4390%;
    display: block;
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 640px) {
  .flex4cl .box {
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

.flex4cl .box img {
  width: 100% !important;
}

.flex4cl .box tr:nth-child(2) {
  font-size: 18px;
  font-weight: 700;
}

.flex4cl .box tr td {
  padding: 0 !important;
  border: 0 !important;
}

.local_title_01 h2 {
  font-size: clamp(28px, 2vw, 32px);
  line-height: 1.25em;
  font-weight: bold;
  color: #fff;
  background-color: #96534c;
  padding: 15px;
}

.local_title_02 h2 {
  font-size: clamp(28px, 2vw, 32px);
  line-height: 1.5em;
  font-weight: bold;
  color: #96534c;
  border-bottom: 1px solid #96534c;
  padding-bottom: 10px;
}

.local_title_02 h2 .sub {
  font-size: 0.6em;
  margin-left: 15px;
  line-height: 1em;
}

.local_title_02 h2 .job {
  display: block;
  font-size: 0.6em;
  line-height: 1em;
}

.sub_title_01 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  font-weight: 500;
  color: #96534c;
  background-color: #F5F0EC;
  padding: 15px 15px 15px 6rem;
}

.sub_title_01 h3::before {
  content: url(../images/contents/dog_icon.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 33px;
  height: 27px;
}

.sub_title_02 h3 {
  font-size: clamp(24px, 2vw, 28px);
  line-height: 1.2em;
  position: relative;
  font-weight: 500;
  color: #96534c;
  background-color: #F5F0EC;
  padding: 15px 15px 15px 6rem;
}

.sub_title_02 h3::before {
  content: url(../images/contents/cat_icon.png);
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  width: 33px;
  height: 27px;
}

.sub_title_03 h3 {
  font-size: 1.2em;
  line-height: 1.2em;
  position: relative;
  font-weight: 500;
  color: #96534c;
  padding-left: 3rem;
}

.sub_title_03 h3::before {
  content: "●";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.img {
  border-radius: 10px;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .img .imgL {
    max-width: 100% !important;
  }
}

.img img {
  transform: scale(1.2);
  transition: 2s;
}

.move {
  transform: none !important;
}

.zoom_out {
  overflow: hidden;
  transition: 2s;
}

.caution {
  background-color: #fff;
  padding: 50px;
}

.caution h3 {
  font-weight: bold;
}

.local_cont_01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 834px) {
  .local_cont_01 {
    display: block;
  }
}

.local_cont_01 .flxL {
  width: 48%;
}

.local_cont_01 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_01 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_01 .flxR {
  width: 48%;
}

@media screen and (max-width: 834px) {
  .local_cont_01 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_01 .flxL img {
  border-radius: 10px;
}

.local_cont_02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  padding: 50px;
}

@media screen and (max-width: 834px) {
  .local_cont_02 {
    display: block;
  }
}

.local_cont_02 .flxL {
  width: 30%;
}

.local_cont_02 .flxL img {
  width: 100% !important;
}

@media screen and (min-width: 640px) and (max-width: 834px) {
  .local_cont_02 .flxL .imgL {
    max-width: 100% !important;
    float: none !important;
  }
}

@media screen and (max-width: 834px) {
  .local_cont_02 .flxL {
    width: 100% !important;
    text-align: center;
  }
}

.local_cont_02 .flxR {
  width: 65%;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .flxR {
    width: 100% !important;
    margin-top: 15px;
  }
}

.local_cont_02 .flxL img {
  border-radius: 10px;
}

.local_cont_02 .hukidashi {
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 15px;
  padding: .8em 1.2em;
  border-radius: 10px;
  background-color: #F7F6F4;
  font-size: 16px;
}

@media screen and (max-width: 834px) {
  .local_cont_02 .hukidashi {
    margin-top: 15px;
    margin-left: auto;
  }
}

.local_cont_02 .hukidashi::before {
  position: absolute;
  left: -15px;
  width: 15px;
  height: 30px;
  background-color: #F7F6F4;
  clip-path: polygon(0 50%, 100% 0, 100% 100%);
  content: '';
}

@media screen and (max-width: 834px) {
  .local_cont_02 .hukidashi::before {
    left: unset;
    top: -15px;
    width: 30px;
    height: 15px;
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
  }
}

.cont_tel {
  color: #96534c;
  font-size: 1.5em;
  font-weight: 500;
}

.local_flex_01 {
  justify-content: space-between;
}

.local_flex_01 .box {
  width: calc((100% - 50px) / 2);
  border: 1px solid #096043;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 50px;
}

@media screen and (max-width: 640px) {
  .local_flex_01 .box {
    width: 100%;
  }
}

.local_flex_01 .box h3 {
  font-weight: 500;
  background-color: #096043;
  padding: 15px;
  font-size: 1.25em;
  text-align: center;
  color: #fff;
}

.local_flex_01 .box article > div {
  padding: 20px;
}

.local_flex_02 {
  justify-content: space-between;
}

.local_flex_02 .box {
  width: calc((100% - 50px) / 2);
  border: 1px solid #096043;
  border-radius: 10px;
  background-color: #fff;
  margin-bottom: 50px;
}

@media screen and (max-width: 640px) {
  .local_flex_02 .box {
    width: 100%;
  }
}

.local_flex_02 .box h3 {
  font-weight: 500;
  background-color: #096043;
  padding: 15px;
  font-size: 1.25em;
  text-align: center;
  color: #fff;
  order: 1;
}

.local_flex_02 .box article {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.local_flex_02 .box article img {
  transform: scale(1.2);
  transition: 2s;
}

.local_flex_02 .box article .imgWrap {
  margin: 30px;
  overflow: hidden;
  border-radius: 10px;
}

.local_flex_02 .box article > div:nth-of-type(1) {
  order: 3;
}

.local_flex_02 .box article > div:nth-of-type(2) {
  order: 4;
  padding: 0 30px 30px;
}

.shop #contents_wrap, .customer #contents_wrap {
  padding-top: 82px;
}

.qa dd, .qa dt {
  border: none !important;
  width: 100% !important;
  line-height: 2.25em;
}

.qa dt {
  position: relative;
  padding: 20px 35px 20px 5.5rem !important;
  background-color: #FDF6EB !important;
}

.qa dt::before {
  content: "Q";
  font-size: 1.25em;
  color: #096043;
  font-weight: 900;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.qa dt::after {
  font-family: "Font Awesome 5 pro";
  content: "\f063";
  position: absolute;
  font-size: 14px;
  right: 5%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.qa dl {
  display: block !important;
  margin-bottom: 20px;
}

.qa dl:last-of-type {
  margin-bottom: 0;
}

/* スクロールをしたら出現する要素にはじめに透過0を指定　*/
.fadeUpTrigger {
  opacity: 0;
}

.fadeIn {
  animation-name: fadeInAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnime {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.dl_01 dt {
  border: none !important;
  padding: 25px 0 !important;
  font-weight: 500;
  color: #96534c;
}

@media screen and (max-width: 640px) {
  .dl_01 dt {
    display: block !important;
    width: 100% !important;
    padding: 15px !important;
  }
}

.dl_01 dd {
  border: none !important;
  padding: 25px 0 !important;
}

@media screen and (max-width: 640px) {
  .dl_01 dd {
    display: block !important;
    width: 100% !important;
    padding: 15px !important;
  }
}

.dl_01 dl {
  border-bottom: 1px solid #ccc;
  align-items: flex-start;
}

@media screen and (max-width: 640px) {
  .dl_01 dl {
    display: block !important;
  }
}

.dl_02 dt {
  border: none !important;
  padding: 0 !important;
  font-size: 1.12em;
  display: block !important;
  width: 100% !important;
  border-bottom: 1px solid #ccc !important;
  padding-bottom: 10px !important;
  margin-bottom: 10px;
  font-weight: 500;
  color: #96534c;
}

.dl_02 dd {
  border: none !important;
  display: block !important;
  width: 100% !important;
  padding: 0 !important;
}

.dl_02 dl {
  display: block !important;
  margin-bottom: 20px;
}

.dl_03 dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  font-weight: bold;
  border-bottom: 1px solid #ebd5cd !important;
  padding-bottom: 10px !important;
}

.dl_03 dd {
  width: 100% !important;
  border: none !important;
}

.dl_03 dd .hukidashi {
  display: flex;
  justify-content: center;
  position: relative;
  width: fit-content;
  margin-top: 15px;
  padding: .8em 1.2em;
  border-radius: 10px;
  background-color: #ffffff;
  font-size: 16px;
}

.dl_03 dd .hukidashi::before {
  position: absolute;
  top: -15px;
  width: 30px;
  height: 15px;
  background-color: #ffffff;
  clip-path: polygon(50% 0, 0 100%, 100% 100%);
  content: '';
}

.dl_03 dl {
  display: block !important;
  background-color: #F1F0EE;
  padding: 20px;
  margin-bottom: 20px;
}

.dl_03 dl:last-of-type {
  margin-bottom: 0;
}

.dl_04 dt {
  border: none !important;
  font-weight: 500;
  color: #fff;
  border-radius: 10px;
  align-items: center !important;
  background-color: #096043 !important;
  margin: 15px 0;
  font-size: 16px;
  padding: 4px 10px !important;
  width: 23% !important;
}

@media screen and (max-width: 640px) {
  .dl_04 dt {
    display: block !important;
    width: 100% !important;
    padding: 15px !important;
  }
}

.dl_04 dd {
  border: none !important;
  padding: 8px 15px !important;
  width: 77% !important;
}

@media screen and (max-width: 640px) {
  .dl_04 dd {
    display: block !important;
    width: 100% !important;
    padding: 15px !important;
  }
}

.dl_04 dl {
  border-bottom: 1px solid #096043;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .dl_04 dl {
    display: block !important;
  }
}

.dl_05 dt {
  display: block !important;
  width: 100% !important;
  border: none !important;
  font-weight: bold;
  border-bottom: 1px solid #ebd5cd !important;
  padding: 0 0 10px 10px !important;
}

.dl_05 dd {
  width: 100% !important;
  border: none !important;
  padding: 10px 0 0 10px !important;
}

.dl_05 dl {
  display: block !important;
  background-color: #F7F6F4;
  padding: 15px;
  margin-bottom: 15px;
}

.dl_05 dl:last-of-type {
  margin-bottom: 0;
}

.list_01 li {
  background-color: #F7F6F4;
  border-radius: 10px;
  padding: 8px 10px;
  line-height: 1em;
  margin-right: 10px;
  margin-bottom: 10px;
}

.form dt {
  border: none !important;
}

.form dd {
  border: none !important;
}

.form dl {
  border-bottom: 1px solid #ccc !important;
  padding: 15px 0;
}

.policy_cont h2 {
  font-size: 1.4em;
  border-bottom: 1px solid #096043;
  padding-bottom: 15px;
  font-weight: 500;
  margin: 50px 0 20px 0;
}

.mail {
  display: none;
}

.confirm {
  margin-top: 200px;
}

.policy {
  padding: 10px 0;
  text-align: center;
}

.message {
  margin-top: 280px !important;
}
